import { Injectable } from '@angular/core';
import { ApiHelper } from '../../api-helper';
import { Observable } from 'rxjs';
import { AllLocationsVolQty } from 'src/cad/flowing-gas/measurement/all-locations-vol-qty/interface/all-locations-vol-qty';
import { UserModelService } from '../../../user/user-model-service';

@Injectable()
export class AllLocationsVolQtyApi {
  private readonly endpoint: string = 'physical/measurement/all/vol-qty';
  constructor(
    private apiHelper: ApiHelper,
    private userModelService: UserModelService ) {      
  }
    
  public getAllLocationsVolQty( acctPeriod: string, assetNbr: number): Observable<AllLocationsVolQty[]> {
    const reqObj = {
      accountingPrd: acctPeriod, assetNbr: this.userModelService.getCurrentContext().assetNbr
    };
    return this.apiHelper.request(this.endpoint, { body: reqObj });
  }
}
