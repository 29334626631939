import {of as observableOf,  Observable } from 'rxjs';
import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter,
  ViewChild,
  ComponentRef,
  Type,
  Injector
} from '@angular/core';
import {
  ColumnApi,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  RowDataChangedEvent,
  SortChangedEvent
} from '@ag-grid-community/core';
import { CadAppController } from 'src/features/common/cad-app/cad-app.controller';
import { GridState, SearchService } from 'src/common/services/search/search-service';
import { UiQueryDirective } from 'common/components/query/query.directive';
import { UiFormComponent } from 'common/components/form/form.component';
import { TableComponent } from 'src/ag-grid-wrapper/table/table.component';
import { CUSTOM_CHIP_DATA } from 'common/types/chip-data';
import { ParamKey } from 'src/common/components/query/param-key';
import { ToFormattedDatePipe } from 'src/cad/common/filters/dates/date.pipe';
import { ItemModel } from 'src/features/common/item/item.model';
import { ItemView } from 'src/features/common/item/item.view';
import { ItemApiService } from 'src/features/common/item/item-api.service';
import * as _ from 'lodash';

enum States {
  empty,
  noresults,
}

@Component({
  selector: 'cad-search',
  templateUrl: './search.component.html',
  styleUrls: [ './search.component.less' ],
})
export class CadSearchComponent implements OnInit, OnDestroy {

  @ViewChild('uiQuery', { read: UiQueryDirective, static: true }) public query: UiQueryDirective;
  @ViewChild('searchForm', { read: UiFormComponent, static: true }) public searchForm: UiFormComponent;
  @ViewChild('resultsTable', { read: TableComponent, static: true }) public resultsTable: TableComponent;
  @Input() public multiSelect: any = false;
  @Input() public actionMenu: any;
  @Input() public showSelect: any = true;
  @Input() public singleFilter: any;
  @Input() public constantParams: any = {};
  @Input() public itemModel: ItemModel;
  @Input() public itemView: ItemView;
  @Input() public itemApi: ItemApiService;
  @Input() public itemSearchForm: Type<any>;
  @Input() public itemFormTopContent: Type<any>;
  @Input() public itemResultsTopContent: Type<any>;
  @Input() public itemSearchList: Type<any>;
  @Input() public itemSearchDates: Type<any>;
  @Input() public searchTitle: string = 'Search';
  @Input() public searchLayout: string = 'fullscreen';
  @Input() public lookupInjector: Injector;
  @Input() public suppressHorizontalScroll: boolean = true;
  @Output() public resultSelected: EventEmitter<any> = new EventEmitter<any>();
  public layoutFullscreen: boolean = false; // shorthands for template
  public layoutTableLookup: boolean = false; // shorthands for template
  public cadAppController: CadAppController;
  public searchService: SearchService;
  public api: (params: any) => Observable<any>;
  public params: any = [];
  public paramKeys: ParamKey[] = [];
  public items: any[] = [];
  public searchGrid: any;
  public resultMessage: string;
  public resultIcon: string;
  public loading: boolean = false;
  public searchReference: ComponentRef<any>;
  public formTopContentReference: ComponentRef<any>;
  public resultsTopContentReference: ComponentRef<any>;
  public constantsReference: ComponentRef<any>;
  public chipInjectors: Injector[] = [];
  public chipParamKeys: ParamKey[] = [];
  public formClasses: any;
  public resultsClasses: any;
  public filtersClasses: any;
  public onResultsTableDestroyedFn: () => void = this.onResultsTableDestroyed.bind(this);
  private toggleRowTimer: any;
  private isFirstSearch: boolean = true;

  //has a char key been pressed on the form - this is used to help enable the SEARCH button
  keyPressed: boolean = false;
  readyForPartials: boolean = false;
  private states: any = States;
  private resultMessages: string[] = [
    'No results to show. Enter some criteria on the left to begin your search.',
    'No results found. Check your search criteria and try again.',
  ];
  private resultIcons: string[] = [
    'find_in_page',
    'youtube_searched_for',
  ];

  constructor(private injector: Injector) {}

  public ngOnInit(): void {
    if (this.lookupInjector) {
      this.cadAppController = this.lookupInjector.get(CadAppController, null); // optional
    }
    if (!this.cadAppController) {
      this.cadAppController = this.injector.get(CadAppController);
    }
    this.searchService = this.injector.get(SearchService);
    switch (this.searchLayout) {
      case 'table-lookup': {
        this.layoutTableLookup = true;
        break;
      }
      case 'fullscreen': {
        this.layoutFullscreen = true;
        break;
      }
      default: {
        console.error(`Unrecognized cad-search layout ${this.searchLayout}`);
        break;
      }
    }
    this.formClasses = {
      'form-fullscreen': this.layoutFullscreen,
      'form-table-lookup': this.layoutTableLookup
    };
    this.resultsClasses = {
      'mat-elevation-z4': this.layoutFullscreen,
      'results-fullscreen': this.layoutFullscreen,
      'results-table-lookup': this.layoutTableLookup
    };
    this.filtersClasses = {
      'filters-fullscreen': this.layoutFullscreen,
      'filters-table-lookup': this.layoutTableLookup
    };
    this.changeState(this.states.empty);
    this.api = this.createSearchApi();
    this.readyForPartials = true; // don't instantiate the partials until we have a lookupInjector if it is passed it
  }

  public ngOnDestroy(): void {
    if (this.toggleRowTimer) {
      clearTimeout(this.toggleRowTimer);
    }
    if (this.formTopContentReference) {
      this.formTopContentReference.destroy();
      this.formTopContentReference = null;
    }
  }

  public onGridReady(event: GridReadyEvent) : void {
    this.setGridFromCachedGridState(event.api, event.columnApi);
  }

  public onResultsTableDestroyed(): void {
    if (this.resultsTable && this.query) {
      let gridState: GridState = this.searchService.getGridState(this.query.context);

      if (gridState) {
        if (this.resultsTable.columnApi.getColumnState().length > 0) {
          gridState.setColumnState(_.cloneDeep(this.resultsTable.columnApi.getColumnState()));
        }
        if (this.resultsTable.columnApi.getColumnGroupState().length > 0) {
          gridState.setColumnGroupState(_.cloneDeep(this.resultsTable.columnApi.getColumnGroupState()));
        }
      }
    }
  }

  public onSearchComponent(cmp: ComponentRef<any>): void {
    this.searchReference = cmp;
    this.params = this.searchReference.instance.params;
    if (this.query
      && this.query.params
      && Object.keys(this.query.params).length > 0) {
      _.assign(this.params, this.query.params);
    } else {
      _.assign(this.params, this.itemModel.defaultSearchParams);
    }
  }

  public onFormTopContentComponent(cmp: ComponentRef<any>): void {
    this.formTopContentReference = cmp;
  }

  public onResultsTopContentComponent(cmp: ComponentRef<any>): void {
    this.resultsTopContentReference = cmp;
  }

  public onConstantsComponent(cmp: ComponentRef<any>): void {
    this.constantsReference = cmp;
    _.assign(this.constantParams, this.constantsReference.instance.params);
  }

  public onRowDataChanged(event: RowDataChangedEvent): void {
    if (event
      && event.api
      && event.columnApi
      && this.searchService
      && this.query) {
      this.setGridFromCachedGridState(event.api, event.columnApi);
    }
  }

  private setGridFromCachedGridState( api: GridApi, columnApi: ColumnApi): void {
    let gridState: GridState = this.searchService.getGridState(this.query.context);
    if (!_.isNil(gridState)) {
      if (!_.isEmpty(gridState.getSortModel())) {
        api.setSortModel(gridState.getSortModel());
      }

      if (!_.isEmpty(gridState.getColumnState())) {
        columnApi.setColumnState(gridState.getColumnState());
      }

      if (!_.isEmpty(gridState.getColumnGroupState())) {
        columnApi.setColumnGroupState(gridState.getColumnGroupState());
      }
      if ((gridState.getSelectedRowNode())) {
        this.setSelectedNode(gridState, api);
      }
      if (!_.isEmpty(gridState.getFilterModel())) {
        api.setFilterModel(gridState.getFilterModel());
      }
    }
  }

  private setSelectedNode(gridState: GridState, api: GridApi): void {
    const selected = gridState.getSelectedRowNode();
    const newRow = api.getRowNode(selected.id);
    if (newRow) {
      newRow.setSelected(true);
    }
  }

  public onSortChanged(event: SortChangedEvent): void {
    if (event && event.api) {
      if (this.searchService && this.query) {
        let gridState: GridState = this.searchService.getGridState(this.query.context);

        if (!_.isNil(gridState)) {
          gridState.setSortModel(!_.isNil(event.api.getSortModel()) ? event.api.getSortModel() : []);
        }
      }
    }
  }

  public onFilterChanged(event: FilterChangedEvent): void {
    if (event && event.api) {
      if (this.searchService && this.query) {
        let gridState: GridState = this.searchService.getGridState(this.query.context);

        if (!_.isNil(gridState)) {
          gridState.setFilterModel(!_.isNil(event.api.getFilterModel()) ? event.api.getFilterModel() : {});
        }
      }
    }
  }

  public restoreColumnVisibility(): void {
    if (this.resultsTable && this.resultsTable.columnApi) {
      this.resultsTable.columnApi.setColumnsVisible(this.resultsTable.columnApi.getAllGridColumns(), true);
    }
  }

  public addViewDetailActionMenu(): void {
    this.actionMenu = {
      title: 'Menu',
      location: 'right',
      items: [
        {
          name: 'Quick View',
          method: this.viewDetail,
          icon: 'details',
        },
      ],
    };
  }

  public select(event: any): void {
    let gridState: GridState = this.searchService.getGridState(this.query.context);
    gridState.setSelectedRowNode(event.node);
    this.resultSelected.emit(event.data);
  }

  public onEnterPressed(event: any): void {
    event.target.blur();  //Simulate Enter Key submitting the form
  }

  public onKeyPressed(event: any ): void {
    if (event && !_.isNil(event.key) && event.key.length === 1) {
      this.keyPressed = true;
    } else if (event
      && event.target
      && _.trim(event.target.value) === '') {
      this.keyPressed = false;
    }
  }

  public removeParam(paramKey: string): void {
    this.query.removeParam(paramKey);
  }

  public setItems(items: any): void {
    this.items = items;
  }

  public setParamKeys(params: ParamKey[]): void {
    if (_.isArray(this.chipInjectors)) {
      this.chipInjectors.splice(0);
    }
    if (_.isArray(this.chipParamKeys)) {
      this.chipParamKeys.splice(0);
    }
    params.forEach((param: ParamKey) => {
      // Do not generate chips when param value is empty
      if (param
        && !_.isNil(param.val)
        && param.val !== '') {
        this.chipInjectors.push(this.createChipInjector(param));
        this.chipParamKeys.push(param);
      }
    });
    this.paramKeys = params;

    for (let key of this.paramKeys) {
      key.fieldLabel = this.getFieldLabel(key.key);
      if (key.val && typeof key.val === 'string' && key.val.match('.*-.*-.*T00:00:00.*')) {
        key.formattedVal = new ToFormattedDatePipe().transform(key.val, 'MM/DD/YY');
      } else {
        key.formattedVal = key.val;
      }
    }
    if (!this.paramKeys || this.paramKeys.length === 0) {
      this.keyPressed = false;
    }
  }

  public clearParams(): void {
    //clear out the params before wacking it in the query
    for (let key of this.paramKeys) {
      this.params[key.key] = null;
    }

    this.query.clearParams();
    this.keyPressed = false;
  }

  public searchClicked(): void {
    this.isFirstSearch = false;
    this.query.refresh();
  }

  /**
   * Will attempt do detect the display name of the item you are searching in
   * @param field name of the field - ngModel
   * @returns {any} Display Name
   */
  private getFieldLabel(field: any): any {
//    this.searchReference.hostView.rootNodes[0].querySelectorAll('ui-datepicker');

    if (!this.searchReference) {
      return field;
    }
    //This implementation gets the html and searches it.  Yea, I know, this sucks - implement something better.
    //It will look for a name attribute that matches the field name, then find the placeholder for that field and return
    // otherwise it returns the field passed in.
    let hostView: any = this.searchReference.hostView;
    let html = hostView.rootNodes[0].outerHTML;
    let fldStartPos = html.indexOf('"' + field + '"');
    if (fldStartPos < 0) { return field; }
    fldStartPos = html.lastIndexOf('<', fldStartPos);
    let fldEndPos = html.indexOf('>', fldStartPos);
    let phStartPos = html.indexOf('placeholder="', fldStartPos) + 13;
    if (phStartPos < 0 || phStartPos > fldEndPos) {
      phStartPos = html.indexOf('[placeholder]="', fldStartPos) + 15;
      if (phStartPos < 0 || phStartPos > fldEndPos) { return field; }
    }
    let endQuotePos = html.indexOf('"', phStartPos);
    return html.substring(phStartPos, endQuotePos);
  }

  public getFieldValue(field: any): any {
    let value = this.query.params[ field ];

    if (_.isDate(value)) {
      value = moment(value).format('MM-DD-YYYY');
    }

    return value;
  }

  public viewDetail(rowEntity: any): void {
    let grid = this.searchGrid.gridApi.grid;
    let gridRow = grid.getRow(rowEntity);

    _.defaults(gridRow, {
      detailName: this.itemModel.name,
      detailTemplate: this.itemView.searchRowDetail,
      detailApi: (this.itemModel.searchRowDetailApi) ? this.itemModel.searchRowDetailApi : this.itemApi,
      detailKey: this.itemModel.searchRowDetailKey,
      // detailHelper: this.itemModel.searchRowDetailHelper,
      // itemLayout: uiLayoutService.buildLayout(this.itemModel.layout)
    });
    if (this.toggleRowTimer) {
      clearTimeout(this.toggleRowTimer);
    }
    this.toggleRowTimer = setTimeout(() => {
      this.searchGrid.toggleRowExpansion(rowEntity);
    });
  }

  public updateUIResults(newValue: any): void {
    if (_.isEmpty(this.query.params)) {
      this.changeState(this.states.empty);
    } else {
      this.changeState(this.states.noresults);
    }
  }

  public changeState(state: any): void {
    this.resultMessage = this.resultMessages[ state ];
    this.resultIcon = this.resultIcons[ state ];
  }

  private createSearchApi(): (params: any) => Observable<any> {
    return this.itemModel.autoSearch ? this.createAutoSearchApi() : this.createNonAutoSearchApi();
  }

  private createAutoSearchApi(): (params: any) => Observable<any> {
    return this.itemApi.filter.bind(this.itemApi);
  }

  private createNonAutoSearchApi(): (params: any) => Observable<any> {
    return (params: any): Observable<any> => {
      if (this.isFirstSearch) {
        this.isFirstSearch = false;
        // If cache is enabled and loaded then return the cached results
        return observableOf((this.query && this.query.cache && this.query.cacheLoaded)
          ? this.query.items
          : []);
      } else {
        return this.itemApi.filter.bind(this.itemApi)(params);
      }
    };
  }

  private createChipInjector = (param: any): Injector => {
    return Injector.create([
      {
        provide: CUSTOM_CHIP_DATA,
        useFactory: () => {
          return {
            param,
            query: this.query,
            name: this.searchForm,
          };
        },
        deps: [],
      },
    ], this.injector);
  }
}
