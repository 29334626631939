export const environment = {
  isReleaseBuild: true,
  production: false,
  envName:'Model Office',
  isDev:true,
  enableDebug: false,
  consoleDebugging: false,
  useCache: false,
  useProxy: true,
  baseApiUrl: '/Services/Messenger/',
  host: 'https://webservicesmo.energytransfer.com',
  port: ':443',
  secondaryUrl:'https://webservicesmo.energytransfer.com',
  infoPostBaseUrl: 'http://mo.peplmessenger.energytransfer.com/mo/ipost',
  extSecurityUrl: 'https://mo.security.energytransfer.net/'
};
