
/**
 * Created by jrayavarap on 1/17/2017.
 */
import { Injectable } from '@angular/core';
import { PostedImbalancesApi } from 'src/cad/common/services/api/flowing-gas/posted-imbalances/posted-imbalances';
import { AllocationPinnedLocationApi } from 'src/cad/common/services/api/flowing-gas/allocation/pinned-location/pinned-location';
import { ShipperImbalanceApi } from 'src/cad/common/services/api/flowing-gas/imbalance/shipper-imbalance/shipper-imbalance';
import { CashoutAdjustmentsApi } from 'src/cad/common/services/api/flowing-gas/cashout/cashout-adjustments/cashout-adjustments.api';
import {AllocationExceptionApi} from 'src/cad/common/services/api/flowing-gas/allocation-exception/allocation-exception';
import {ContractLocationQuantitiesApi} from 'src/cad/common/services/api/flowing-gas/imbalance/shipper-imbalance/contract-location-quantities';
import {SchedulingChargeLocationViewApi} from 'src/cad/common/services/api/flowing-gas/scheduling-charge/location-view/location-view';
import {SchedulingChargeContractViewApi} from 'src/cad/common/services/api/flowing-gas/scheduling-charge/contract-view/contract-view';
import {SchedulingChargeWaiverApi} from 'src/cad/common/services/api/flowing-gas/scheduling-charge/scheduling-charge-waiver/scheduling-charge-waiver';
import {GDSContractLookupApi} from 'src/cad/common/services/api/flowing-gas/scheduling/gds-contract-lookup/gds-contract-lookup';
import {GDSImbalanceStorageSchemeApi} from 'src/cad/common/services/api/flowing-gas/scheduling/gds-imbalance-storage-scheme/gds-imbalance-storage-scheme';
import { StorageBalancesApi } from 'src/cad/common/services/api/flowing-gas/storage-balances/storage-balances';
import {GDSImbalanceApi} from 'src/cad/common/services/api/flowing-gas/scheduling/gds-imbalance/gds-imbalance';
import {MorningReportApi} from 'src/cad/common/services/api/flowing-gas/scheduling/morning-report/morning-report';
import {OBAContractLookupApi} from 'src/cad/common/services/api/flowing-gas/scheduling/oba-contract-lookup/oba-contract-lookup';
import {MorningReportOutageApi} from 'src/cad/common/services/api/flowing-gas/scheduling/morning-report/morning-report-outage';
import {MorningReportStorageApi} from 'src/cad/common/services/api/flowing-gas/scheduling/morning-report/morning-report-storage';
import {MorningReportShipScheduleApi} from 'src/cad/common/services/api/flowing-gas/scheduling/morning-report/morning-report-ship-schedule';
import {PointLookupApi} from 'src/cad/common/services/api/flowing-gas/scheduling/point-lookup/point-lookup';
import {SegmentLookupApi} from 'src/cad/common/services/api/flowing-gas/scheduling/segment-lookup/segment-lookup';
import {NominationConfirmationApprovalApi} from 'src/cad/common/services/api/flowing-gas/scheduling/nomination-confirmation-approval/nomination-confirmation-approval';
import {ViewPoolBalanceInfoApi} from 'cad/common/services/api/flowing-gas/scheduling/view-pool-balance-info/view-pool-balance-info.api';
import { UpdateBalanceDateApi } from 'src/cad/common/services/api/flowing-gas/update-balance-date/update-balance-date';
import { MaintainParkingLimitsApi } from 'src/cad/common/services/api/flowing-gas/scheduling/maintain-parking-limits/maintain-parking-limits';
import { AutomaticSchedulingApi } from 'src/cad/common/services/api/flowing-gas/scheduling/auto-scheduling/automatic-scheduling/automatic-scheduling';
import {AutoParkServiceApi} from 'src/cad/common/services/api/flowing-gas/auto-park-service/auto-park-service';
import {AutomaticSchedulingParamsApi} from 'cad/common/services/api/flowing-gas/scheduling/auto-scheduling/automatic-scheduling-params/automatic-scheduling-params';
import { AllocateDailyApi } from 'cad/common/services/api/flowing-gas/allocate-daily/allocate-daily';
import { ViewImbalanceApi } from './view-imbalance/view-imbalance';
import {
  ShipperImbalanceSummaryApi
} from 'cad/common/services/api/flowing-gas/imbalance/shipper-imbalance-summary/shipper-imbalance-summary';
import {OBAMonitoringApi} from 'cad/common/services/api/flowing-gas/oba-monitoring/oba-monitoring-api';
import { CashoutDetailsApi } from './cashout/cashout-details/cashout-details.api';
import { AllLocationsVolQtyApi } from './measurement/all-locations-vol-qty.api';

@Injectable()
export class FlowingGasApi {
  constructor(
    public postedImbalancesApi: PostedImbalancesApi,
    public allocationPinnedLocationApi: AllocationPinnedLocationApi,
    public allocationExceptionApi: AllocationExceptionApi,
    public shipperImbalanceApi: ShipperImbalanceApi,
    public contractLocationQuantitiesApi: ContractLocationQuantitiesApi,
    public cashoutAdjustmentsApi: CashoutAdjustmentsApi,
    public cashoutDetailsApi: CashoutDetailsApi,
    public gDSImbalanceStorageSchemeApi: GDSImbalanceStorageSchemeApi,
    public schedulingChargeLocationViewApi: SchedulingChargeLocationViewApi,
    public schedulingChargeContractViewApi: SchedulingChargeContractViewApi,
    public schedulingChargeWaiverApi: SchedulingChargeWaiverApi,
    public gDSContractLookupApi: GDSContractLookupApi,
    public storageBalancesApi: StorageBalancesApi,
    public gDSImbalanceApi: GDSImbalanceApi,
    public morningReportApi: MorningReportApi,
    public oBAContractLookupApi: OBAContractLookupApi,
    public morningReportOutageApi: MorningReportOutageApi,
    public morningReportStorageApi: MorningReportStorageApi,
    public morningReportShipScheduleApi: MorningReportShipScheduleApi,
    public pointLookupApi: PointLookupApi,
    public segmentLookupApi: SegmentLookupApi,
    public nominationConfirmationApprovalApi: NominationConfirmationApprovalApi,
    public viewPoolBalanceInfoApi: ViewPoolBalanceInfoApi,
    public updateBalanceDateApi: UpdateBalanceDateApi,
    public obaMonitoringApi: OBAMonitoringApi,
    public maintParkingLimitsApi: MaintainParkingLimitsApi,
    public automaticScheduling: AutomaticSchedulingApi,
    public automaticSchedulingParamsApi: AutomaticSchedulingParamsApi,
    public autoParkServiceApi: AutoParkServiceApi,
    public allocateDailyApi:AllocateDailyApi,
    public viewImbalanceApi:ViewImbalanceApi,
    public shipperImbalanceSummaryApi:ShipperImbalanceSummaryApi,
    public allLocationsVolQtyApi:AllLocationsVolQtyApi

  ) {}
}
